import React from 'react'
import Layout from '../components/ui-components/containers/layout'
import HeroImage from '../components/ui-components/hero-image'
import DonateButton from '../components/ui-components/donate-button'
import TextContainer from '../components/ui-components/containers/text-container'
import { Link } from 'gatsby'
import Button from '../components/ui-components/button'
import TeamPreview from '../components/index/team-preview'
import Countdown from '../components/ui-components/countdown'
import YouTube from 'react-youtube'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import SEO from '../components/seo/SEO'

const RootIndex = () => {
  const videoOpts = {
    playerVars: {
      autoplay: 0,
    },
  }

  const YOUTUBE_ID = '2a9QXBXdUiA'

  return (
    <Layout>
      <SEO />
      <HeroImage></HeroImage>

      {/**
        <div
          className="h-32"
          style={{
            background:
              'linear-gradient(180deg, rgb(0, 0, 0, 1), rgba(26, 32, 44, 1))',
          }}
        ></div>
        <div
          style={{
            background:
              'linear-gradient(180deg, rgba(26, 32, 44, 1), rgb(217, 184, 17, 0.8))',
          }}
        >
         */}

      <TextContainer>
        <div className="w-full flex justify-center items-center mt-24 mb-24 block md:hidden">
          <YouTube
            videoId={YOUTUBE_ID}
            opts={videoOpts}
            className="w-64 h-48"
          />
        </div>

        <div className="w-full flex justify-center items-center mt-24 mb-8 hidden md:flex">
          <YouTube videoId={YOUTUBE_ID} opts={videoOpts} />
        </div>

        <div className="mt-4 w-3/4 md:w-1/2 m-auto mb-48">
          <Button type="primary">
            <OutboundLink
              className="font-handwritten text-xl w-full text-center"
              href="https://www.betterplace.me/weihnachtsgeschenke-fuer-benachteiligte-kinder"
            >
              Zur Kampagne
            </OutboundLink>
          </Button>
        </div>
      </TextContainer>

      <TextContainer>
        <div className="pt-24 flex justify-between flex-col">
          <h2 className="text-3xl font-handwritten text-center w-full">
            <strong className="text-4xl my-2">
              In Deutschland lebt jedes fünfte Kind in Armut – das sind
              insgesamt 2,55 Millionen Kinder.
            </strong>
          </h2>
          <span className="mt-4 text-center">
            Viele dieser Kinder sind auf die Hilfe der über 940 Tafeln in
            Deutschland angewiesen; für sie gibt es extra Kindertafeln, da auf
            sie zuhause kein warmes Mittagessen wartet. Wo das Geld bereits für
            das Essen fehlt, setzt sich die Armut unter dem Tannenbaum weiter
            fort.
          </span>
          <div className="mt-4 w-full md:w-1/2 m-auto">
            <Button type="primary">
              <Link
                to="/spenden"
                className="text-center font-handwritten text-xl w-full"
              >
                Finde heraus, wie du helfen kannst
              </Link>
            </Button>
          </div>
        </div>
      </TextContainer>

      <div className="w-full bg-brand mt-24 py-8 text-center">
        <TextContainer>
          <h2 className="text-4xl font-handwritten font-bold ">
            Unsere Vision
          </h2>
          <div className="mt-4">
            Wir möchten, dass <strong>jedes</strong> Kind in Deutschland ein
            wundervolles und unbeschwertes Weihnachtsfest erleben kann.
            Wenigstens einmal im Jahr soll es für sie nichts als pure{' '}
            <strong>Freude</strong> und <strong>Glück</strong> geben – ohne das
            Gefühl, auf etwas verzichten zu müssen. Kein Kind sollte sich
            aufgrund äußerer Umstände, für die es überhaupt nichts kann, auch
            noch an Weihnachten benachteiligt fühlen. Genau das umzusetzen –
            darum geht es bei <strong>Shine On</strong>. Mit Ihrer Hilfe sorgen
            wir dafür, dass
            <p className="text-3xl font-handwritten">
              die Augen eines jeden Kindes weiter strahlen.
            </p>
          </div>
        </TextContainer>
      </div>

      <TeamPreview></TeamPreview>

      <div className="mt-12 pb-12 w-full flex justify-center items-center">
        <div className="w-full md:w-1/2">
          <DonateButton></DonateButton>
        </div>
      </div>
    </Layout>
  )
}

export default RootIndex

import React from 'react'
import Button from './button'
import { Link } from 'gatsby'

const DonateButton = () => {
  return (
    <div className=" flex justify-center items-center flex-col px-4 md:px-0">
      <span className="font-bold text-2xl mr-2 font-handwritten text-center">
        Du möchtest auch ein Lächeln schenken?
      </span>

      <div className="mt-2 w-full md:w-1/3 m-auto">
        <Button type="primary">
          <Link
            to={'/spenden'}
            className="text-center font-handwritten text-xl w-full"
          >
            Jetzt Spenden
          </Link>
        </Button>
      </div>
    </div>
  )
}

export default DonateButton

import React from 'react'
import Button from '../ui-components/button'
import { Link } from 'gatsby'

const TeamPreview = () => {
  return (
    <div className="container mx-auto relative">
      <div className="flex justify-center md:px-6 mt-24 pb-12">
        <div className="w-full xl:w-3/4 lg:w-11/12 flex">
          {/** Image */}
          <img
            src="https://via.placeholder.com/1600x800"
            className="rounded-lg h-128 hidden md:block"
          />

          {/** Card */}
          <div className="w-full lg:w-1/3 h-112 border-brand overflow-hidden relative md:absolute right-0 px-8  md:mr-12 flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg border-brand border-r-4 h-90 flex flex-col justify-between">
              <div className="flex flex-col">
                <h3 className="pt-2 mb-2 text-2xl font-handwritten w-full text-center">
                  Unser Team
                </h3>
                <div className="text-sm text-gray-700 text-center">
                  Wir - Manu (27) und Jeanine (25) - wissen durch Erfahrungen im
                  eigenen Bekanntenkreis, wie viele Kinder sogar an Weihnachten
                  leer ausgehen, weil ihre Eltern sich einfach kein
                  Weihnachtsgeschenk leisten können. Wir wollen genau hier
                  ansetzen und diesen Familien helfen, weshalb wir dieses
                  Projekt ins Leben gerufen haben – damit jedes Kind weiterhin
                  sein Lächeln im Gesicht behält.
                </div>
              </div>

              <div className="mt-4">
                <Button type="primary">
                  <Link to={'/team'} className="w-full text-center">
                    Erfahre mehr
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TeamPreview

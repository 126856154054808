import React from 'react'
import placeholder from '../../img/hero_img_placeholder.jpg'
import '../../styles/styles.css'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "header/header_1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className="relative bg-black">
      <div
        className="w-full h-full bg-black absolute flex justify-center items-center px-12 py-8 z-10"
        style={{ background: 'rgba(0,0,0,0.8)' }}
      >
        <div className="flex justify-center items-center w-full">
          <h2 className="text-brand text-xl text-center md:text-5xl font-handwritten mt-20">
            Hilf uns dabei, Freude zu schenken
          </h2>
          <div className="scroll-indicator pt-4 hidden md:block"></div>
        </div>
      </div>
      <div className="overflow-hidden">
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt=""
          style={{ maxHeight: '600px' }}
        />
      </div>

      {/**
      <div
        className="parent h-140 w-screen z-0"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gridTemplateRows: 'repeat(5, 1fr)',
          gridGap: '1rem',
        }}
      >
        <div
          className="div1 overflow-hidden"
          style={{ gridArea: '2 / 2 / 5 / 5', background: 'darkgray' }}
        ></div>
        <div
          className="div2"
          style={{
            gridArea: '1 / 1 / 3 / 2',
            background: 'lightgray',
          }}
        ></div>
        <div
          className="div3"
          style={{
            gridArea: '1 / 2 / 2 / 4',
            background: 'darkgray',
          }}
        ></div>
        <div
          className="div4"
          style={{
            gridArea: '1 / 4 / 2 / 6',
            background: 'gray',
          }}
        ></div>
        <div
          className="div5"
          style={{ gridArea: '2 / 5 / 4 / 6', background: 'lightgray' }}
        ></div>
        <div
          className="div6"
          style={{ gridArea: '4 / 5 / 6 / 6', background: 'gray' }}
        ></div>
        <div
          className="div7"
          style={{ gridArea: '5 / 4 / 6 / 5', background: 'darkgray' }}
        ></div>
        <div
          className="div8"
          style={{ gridArea: '5 / 1 / 6 / 3', background: 'lightgray' }}
        ></div>
        <div
          className="div9"
          style={{ gridArea: '4 / 1 / 5 / 2', background: 'gray' }}
        ></div>
        <div
          className="div10"
          style={{ gridArea: '3 / 1 / 4 / 2', background: 'lightgray' }}
        ></div>
        <div
          className="div11"
          style={{ gridArea: '5 / 3 / 6 / 4', background: 'gray' }}
        ></div>
      </div>
             */}
    </div>
  )
}

export default HeroImage
